@import "../scss/index.scss";

.layout-admin {
  transition: margin-left 0.2s;

  &__header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    padding: 0;
    height: 65px;
    background: $background-header;
    //box-shadow: 0px 3px 3px -2px grey;
  }
  &__content {
    min-height: calc(100vh - 65px);
    padding: 60px 25px 25px 25px;
  }
  &__footer {
    //border-top: 1px solid rgb(241, 241, 241);
    background-color: $background-header;
    color: white;
    //font-weight: bold;
    text-align: center;
  }
}
