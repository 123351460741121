.edit-notification-form {
  .form-edit {
    //text-align: center;

    .ant-form-item {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    i {
      color: rgba(0, 0, 0, 0.25);
    }

    .ant-select {
      width: 100%;
    }

    .btn-submit {
      width: 100%;
    }

    .site-tag-plus {
      background: #fff;
      border-style: dashed;
    }
    .edit-tag {
      user-select: none;
    }
    .tag-input {
      width: 88px;
      margin-right: 8px;
      vertical-align: top;
    }

    .ql-container {
      border-bottom-left-radius: 0.5em;
      border-bottom-right-radius: 0.5em;
      background: #fefcfc;
    }

    /* Snow Theme */
    .ql-snow.ql-toolbar {
      display: block;
      background: #eaecec;
      border-top-left-radius: 0.5em;
      border-top-right-radius: 0.5em;
    }

    /* Bubble Theme */
    .ql-bubble .ql-editor {
      border: 1px solid #ccc;
      border-radius: 0.5em;
    }

    .ql-editor {
      min-height: 20em;
    }
  }
}
