.edit-type-form {
  .form-edit {
    //text-align: center;

    .ant-form-item {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    i {
      color: rgba(0, 0, 0, 0.25);
    }

    .ant-select {
      width: 100%;
    }

    .btn-submit {
      width: 100%;
    }
  }
}
