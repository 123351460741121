@import "../../../scss/index.scss";

.condiciones {
  //background-image: url("../../../assets/img/png/Home.png");
  background-color: black;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &__footer {
    background-color: rgb(173, 213, 44);
    color: rgb(30, 40, 82);
    font-weight: bold;
    text-align: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 70px);
    overflow: auto;

    &-logo {
      padding: 40px 20px;
      img {
        width: 100%;
        max-width: 300px;
        //margin-left: 10%;
      }
    }

    &-subtitle {
      padding-bottom: 10px;
      font-size: 0em + 22px / $defaultFontSize;
      color: white;
    }

    &-title {
      margin-bottom: 60px;
      color: white;

      h1 {
        color: white;
        font-size: 26px;
        font-weight: bold;
      }
    }
    &-p {
      color: white;
      font-size: 20px;
      text-align: justify;
      margin: 0px 80px 0px 80px;

      strong {
        font-size: 24px;
      }
    }
  }
}
